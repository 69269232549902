<template>
  <div>
    <b-form-checkbox
      v-model="mark"
      class="mt-2 mx-auto"
      @change="handleMarkChange"
    />
  </div>
</template>

<script>

export default {
  name: 'CellRendererAttendance',
  components: {

  },
  props: {
    params: Object,
  },
  data() {
    return {
      mark: false,
    }
  },
  created() {
    if (this.params.attendance) {
      this.mark = true
    } else {
      this.mark = false
    }
  },
  methods: {
    handleMarkChange() {
      const data = {}
      data.booking_id = this.params.booking_id
      data.attendance = this.mark

      this.$http
        .put('/api/book', { data })
        .then(response => {
          if (response.data.success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Event Application Updated',
                  icon: 'CheckCircleIcon',
                  text: 'Attendance status updated',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Feedback Recording Failed',
                  icon: 'AlertCircleIcon',
                  text: 'We could not update the candidates feedback.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.$refs.wizard.reset()
            window.location.reload()
          }
        })
        .catch(error => {})
    },
  },
}
</script>
